import "./App.css";
import LoginForm from "./LoginForm";
import { Route, Routes } from "react-router-dom";
// import Callback from "./Callback";
// import PrivateRoute from "./PrivateRoute";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/*" element={<LoginForm />} />
        {/* <Route
          path="/callback"
          element={
            <PrivateRoute>
              <Callback />
            </PrivateRoute>
          }
        /> */}
      </Routes>
    </div>
  );
}

export default App;
