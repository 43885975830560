import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { useAuth } from "react-oidc-context";

function LoginForm() {
  const auth = useAuth();
  return (
    <div className="Login-form">
      <h1>SAMLEIKI</h1>
      <div className="Logo-container">
        <FontAwesomeIcon
          icon={faUser}
          height={50}
          width={50}
          className="User-icon"
        />
      </div>
      {auth.isAuthenticated ? (
        <Fragment>
          <div className="Profile-container">
            {Object.keys(auth.user.profile).map((value, index) => (
              <Fragment key={index}>
                <div className="text-container">
                  <span className="Key-text">{value + ": "}</span>
                  <span className="Value-text">{auth.user.profile[value]}</span>
                </div>
              </Fragment>
            ))}
          </div>
          <button
            className="styled-button"
            onClick={() =>{
              auth.signoutSilent({post_logout_redirect_uri:"https://portal.fo"});
            }}
          >
            Rita út
          </button>
        </Fragment>
      ) : (
        <button
          className="styled-button"
          onClick={() => {
              auth.signinRedirect()
          }}
        >
          Rita inn
        </button>
      )}
    </div>
  );
}

export default LoginForm;